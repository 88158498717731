/**
 * The `sanitizeSearchQuery` function ensures that user search queries are formatted correctly
 * and do not break the search functionality due to special characters.
 *
 * Steps:
 * 1. **Trim Spaces**: Removes leading and trailing spaces.
 * 2. **Replace Multiple Spaces**: Converts multiple spaces into a single space.
 * 3. **Normalize Special Characters**:
 *    - Converts backslashes (`\`) to empty strings to prevent breaking queries.
 *    - Ensures quotes (`"`) don’t interfere with the search.
 *
 * @param {string} query - The raw user input for the search query.
 * @returns {string} - A sanitized search query, safe for API requests.
 */
export const sanitizeSearchQuery = (query: string): string => {
  return query
    .trim() // Remove leading & trailing spaces
    .replace(/\s+/g, ' ') // Normalize multiple spaces into a single space
    .replace(/\\/g, '') // Remove backslashes
    .replace(/"/g, '\\"'); // Escape leading & trailing quotes
};
