<template>
  <Tabs
    v-if="categoryBCData.total > 0"
    class="w-full !justify-start"
    align="left"
    @update:tab="(tabName: string) => updateTab(tabName)"
    :data-test-id="selectedTab === 'Products' ? 'productsTab' : 'articlesTab'"
  >
    <Tab
      title="Products"
      icon="cart"
      :size="16"
      :count="categoryBCData.total"
      class="px-2xs md:px-none"
      data-test-id="productsTabBtn"
    >
      <CategoryToolbar @sort-by="(val: string) => onSortBy(val, true)" />

      <CategoryGrid
        :prices="pricesRef"
        :items-per-page="itemsPerPage"
        :total-found-items="categoryBCData.total"
        v-if="categoryBCData"
        :is-search-page="false"
        :fetch-new-page-on-search-page="fetchNewPageOnSearchPage"
        :initial-sort="sortTerm"
        :is-loading="loadingItems"
        :is-search="true"
      />
    </Tab>

    <Tab
      :disabled="Number(totalArticles) === 0"
      title="Articles"
      icon="article"
      :size="16"
      :count="totalArticles"
      class="px-2xs md:px-none"
      data-test-id="articlesTabBtn"
    >
      <div class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 w-full gap-x-xs gap-y-xs lg:gap-x-sm mt-md">
        <GridItem col-span="1" :breakpoints="{}" v-for="(article, index) in a" :key="index" v-if="a">
          <BlogPost
            :key="index"
            :article="{
              title: article.displayName,
              category: article?.multiselect?.selectionValues,
              image: getSrcOf(article.image),
              date: formatArticleDate(article.date),
              link: getArticleLink(article),
              meta_description: article.meta_description,
              readingTime: article.readingTime,
              linkComponent: NuxtLink,
            }"
            direction="col"
            data-test-id="listedArticles"
          />
        </GridItem>
      </div>
    </Tab>
  </Tabs>
</template>

<script setup lang="ts">
import { useContentHelpers } from "@/composables/UseContent/helpers";
import { formatArticleDate, getArticleLink } from "@/composables/UseContent/useArticleList";
import type { Page } from "@bloomreach/spa-sdk";

// Component Props
const { a, totalArticles, page } = defineProps<{
  a: any;
  totalArticles: any;
  page: Page;
}>();

const emit = defineEmits<{
  (event: "updateTab", tab: string): string;
}>();

// // Component Composables
const { selectedBranch } = useBranches();
const { onSortBy, itemsPerPage, fetchNewPageOnSearchPage } = useCategoryPage();

const { data: categoryBCData, loadingItems } = useCategory();
const { sortTerm } = useSortBy();
const { user } = useUser();
const { getPrice } = usePrices();
const { getSrcOf } = useContentHelpers(page);

// Component Refs
const selectedTab = ref<string>("Products");
const pricesRef = ref<Record<string, number>>({});

const NuxtLink = resolveComponent("NuxtLink");

const updateTab = (tabName: string) => {
  selectedTab.value = tabName === "Articles" ? "Articles" : "Products";

  emit("updateTab", selectedTab.value);
};

const getBasePrices = async (): Promise<Record<string, number>> => {
  return categoryBCData.value?.items?.map((item: any) => {
    const desiredKeys = ["price", "offer_price", "price_inc_vat", "offer_price_inc_vat", "unit", "offer_unit"];

    const result: { [key: string]: any } = {};

    desiredKeys.forEach((key) => {
      const attr = item.customAttrs.find((attribute: any) => attribute.name === key);
      if (attr) {
        result[key as string] = attr.values[0] as string;
      }
    });

    return {
      product_sku: item.itemId.id,
      branch_id: selectedBranch.value?.id,
      ...result,
    };
  });
};

// Component Watchers
watch(
  [user, categoryBCData],
  async () => {
    await nextTick();

    if (user.value) {
      pricesRef.value = await getPrice(categoryBCData.value?.items?.map((item: any) => item?.itemId?.id)?.join(","));
    } else if (selectedBranch.value) {
      await nextTick();
      pricesRef.value = await getBasePrices();
    }
  },
  { immediate: true },
);
</script>
