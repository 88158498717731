import type { Maybe } from "@vue-storefront/unified-data-model";
import { sanitizeSearchQuery } from "mkm-avengers";

export interface UseKeywordRedirectState {
  redirectsUrl: Maybe<string> | undefined;
  error: any;
}

const useKeywordRedirect = () => {
  const state = useState<UseKeywordRedirectState>(`useKeywordRedirect`, () => ({
    redirectsUrl: null,
    error: null,
  }));

  const fetchKeywordRedirects = async (keyword: string) => {
    const { data, error } = await useAsyncData("", () =>
      useSdk().brSearch.findItemsByKeyword({
        text: sanitizeSearchQuery(keyword),
        limit: 200,
        expect: "queryHint { redirectHint { url } }",
      }),
    );

    useHandleError(error.value);

    state.value.redirectsUrl = data.value?.queryHint?.redirectHint?.url;
  };

  return {
    ...toRefs(state.value),
    fetchKeywordRedirects,
  };
};

export default useKeywordRedirect;
