<template>
  <div>
    <component :is="article.linkComponent" :to="article.link">
      <Stack :direction="direction" gap="xs">
        <NuxtImg
          v-if="article.image"
          format="avif, webp, jpeg"
          :src="article.image"
          :alt="article.title"
          :data-test-id="`${categoryTestId}Image`"
          loading="lazy"
          :class="[
            'ui-object-cover',
            direction === 'row'
              ? 'ui-w-[100px] ui-h-[100px] md:ui-w-[160px] md:ui-h-[160px]'
              : article.isHero
              ? 'h-[25rem] md:h-[30rem] lg:h-[35rem]'
              : 'h-[16rem] sm:h-[15rem] lg:h-[18rem] w-full lg:w-[510px] xl:w-[820px]',
          ]"
        />
        <div :class="[article.isHero && 'px-xs sm:p-none']">
          <Text
            v-if="article.category?.selectionValues"
            size="xs"
            class="ui-mb-2xs"
            weight="semi"
            :data-test-id="`${categoryTestId}Category`"
          >
            {{ article.category.selectionValues[0].label }}
          </Text>

          <Heading
            v-if="article.isHero"
            size="sm"
            data-test-id="heroArticleHeading"
          >
            {{ article.title }}
          </Heading>
          <Text
            v-else
            size="md"
            weight="bold"
            :data-test-id="`${categoryTestId}Name`"
          >
            {{ article.title }}
          </Text>
          <Text
            :size="article.isHero ? 'md' : 'sm'"
            weight="semi"
            class="ui-mt-2xs ui-line-clamp-2 ui-text-ellipsis ui-text-charcoal-light"
            :data-test-id="`${categoryTestId}Description`"
          >
            {{ article.meta_description }}
          </Text>
          <Text
            size="xs"
            weight="semi"
            class="ui-mt-2xs line-clamp-2 text-ellipsis text-sm ui-text-grey-darker"
          >
            {{ article.date }}
            <span v-if="article.readingTime">
              . {{ article.readingTime }} min read
            </span>
          </Text>
        </div>
      </Stack>
    </component>
  </div>
</template>

<script setup lang="ts">
import type { ConcreteComponent } from "vue";
import Text from "../Typography/Text/Text.vue";
import Stack from "../Layout/Stack/Stack.vue";
import Heading from "../Typography/Heading/Heading.vue";

type Article = {
  link: string;
  image: string;
  category: {
    selectionValues: {
      key: string;
      label: string;
    }[];
  };
  title: string;
  meta_description: string;
  date: string;
  readingTime: string;
  isHero?: boolean;
  linkComponent: string | ConcreteComponent;
};

const { article, direction } = withDefaults(
  defineProps<{ article: Article; direction: "row" | "col" }>(),
  {
    direction: "col",
  },
);

const isArticle = article?.category?.selectionValues?.[0];

const categoryTestId = isArticle?.label
  ? isArticle.label.replace(/\s+/g, "")
  : "blog";
</script>
